@import "/src/styles/variables";

.card {
    height: 100%;
    flex-direction: column;
    display: flex;
    gap: 30px;
    aspect-ratio: 5/6;
    padding: var(--block-padding);
    border-radius: $main-border-radius;
    background-color: $light-dark;
    @media screen and (max-width: $mobile) {
        height: auto;
        aspect-ratio: unset;
        gap: 15px;
        padding: 1rem;
        max-width: calc(100vw - var(--container-big-padding)*3);
    }
    &__illustration {
        border-radius: 15px;
        overflow: hidden;
        height: 100%;
        position: relative;
        @media screen and (max-width: $mobile) {
            aspect-ratio: 1;
        }
        img {
            width: 101%;
            height: 101%;
            object-fit: cover;
            position: relative;
            left: -0.5%;
            top: -0.5%;
        }
        &__tag {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background: $light;
        }
    }
    &__details {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 20px;
        padding: 0 10px;
        &__title {
            font-size: var(--title-font-size);
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__tag {
            text-transform: capitalize;
        }
    }
}