@import "/src/styles/variables";

.header-desktop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--global-gap);
    height: 100%;

    text-align: center;
    // color: $primary;
    &__wrapper {
        display: flex;
        flex-direction: column;
        padding: var(--block-padding);
        border-radius: $main-border-radius;
        background-color: $light-dark;
        &:nth-child(1) {
            justify-content: flex-start;
            background-color: $primary;
        }
        &:nth-child(2) {
            justify-content: center;
            flex: 1;
            gap: 20px;
        }
        &:nth-child(3) {
            justify-content: flex-end;
            gap: 40px;
        }
    }
}