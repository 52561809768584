@import "/src/styles/variables";

.tag {
    display: flex;
    text-transform: capitalize;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    white-space: nowrap;
    gap: 10px;
    --medium-height: 40px;
    --medium-padding: 15px;
    --medium-font-size: 16px;
    --big-height: 55px;
    --big-padding: 30px;
    --big-font-size: 20px;
    @media screen and (max-width: $mobile) {
        --big-height: var(--medium-height);
        --big-padding: var(--medium-padding);
        --big-font-size: var(--medium-font-size);
    }
    &--circle {
        padding: 0;
        width: 40px;
    }
    &--medium {
        height: var(--medium-height);
        padding: 0 var(--medium-padding);
        font-size: var(--medium-font-size);
    }
    &--big {
        height: var(--big-height);
        padding: 0 var(--big-padding);
        font-size: var(--big-font-size);
    }

    &--border {
        &.tag--firstcolor--primary {
            color: $primary;
            border: 2px solid $primary;
        }
        &.tag--firstcolor--secondary {
            color: $secondary;
            border: 2px solid $secondary;
        }
        &.tag--firstcolor--tertiary {
            color: $tertiary;
            border: 2px solid $tertiary;
        }
        &.tag--firstcolor--quaternary {
            color: $quaternary;
            border: 2px solid $quaternary;
        }
        &.tag--firstcolor--light {
            color: $light;
            border: 2px solid $light;
        }
    }

    &--fill {
        &.tag--firstcolor--primary {
            color: $primary;
        }
        &.tag--firstcolor--secondary {
            color: $secondary;
        }
        &.tag--firstcolor--tertiary {
            color: $tertiary;
        }
        &.tag--firstcolor--quaternary {
            color: $quaternary;
        }
        &.tag--firstcolor--light {
            color: $light;
        }
        &.tag--secondcolor--transparent {
            background: transparent;
            border: 2px solid transparent;
        }
        &.tag--secondcolor--primary {
            background-color: $primary;
            border: 2px solid $primary;
        }
        &.tag--secondcolor--secondary {
            background-color: $secondary;
            border: 2px solid $secondary;
        }
        &.tag--secondcolor--tertiary {
            background-color: $tertiary;
            border: 2px solid $tertiary;
        }
        &.tag--secondcolor--quaternary {
            background-color: $quaternary;
            border: 2px solid $quaternary;
        }
        &.tag--secondcolor--light {
            background-color: $light;
            border: 2px solid $light;
        }
    }
}