@import "/src/styles/mixins";

.header-mobile {
    position: relative;
    z-index: 2;
    &__nav,
    &__behance {
        flex: 1;
    }
    &__behance {
        display: flex;
        justify-content: flex-end;
    }
    &__inner {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 1rem;
    }
}