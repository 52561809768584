@import "/src/styles/variables";

.field {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    column-gap: 15px;

    &__select,
    &__input {
        min-width: 300px;
        background-color: $dark;
        border-radius: $main-border-radius;
        padding: 10px 15px;
        border-radius: 8px;
        border: 0;
        color: $light;
        font-family: inherit;
        font-size: var(--global-font-size);

        &:focus,
        &:focus-visible,
        &:focus-within {
            outline: 0;
        }
    }

    &__checkbox {
        position: relative;
        display: inline-block;
        width: 40px; // 60px / 1.5
        height: 23px; // 34px / 1.5, arrondi
        &__input {
            opacity: 0;
            width: 0;
            height: 0;
            &:checked {
                + .field__checkbox__slider {
                    background-color: $primary;
                    &:before {
                        -webkit-transform: translateX(17px); // 26px / 1.5, arrondi
                        -ms-transform: translateX(17px); // 26px / 1.5, arrondi
                        transform: translateX(17px); // 26px / 1.5, arrondi
                    }
                }
            }
        }
        &__slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 23px; // 34px / 1.5, arrondi
            background-color: $dark;
            -webkit-transition: .4s;
            transition: .4s;
            &:before {
                position: absolute;
                content: "";
                height: 17px; // 26px / 1.5, arrondi
                width: 17px; // 26px / 1.5, arrondi
                border-radius: 50%;
                left: 3px; // 4px / 1.5, arrondi
                bottom: 3px; // 4px / 1.5, arrondi
                background-color: $light;
                -webkit-transition: .4s;
                transition: .4s;
            }
        }
    }

    &--checkbox {
        flex-direction: row;
        align-items: center;
    }
}