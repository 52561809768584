@import "/src/styles/variables";
@import "src/styles/mixins";

.profil {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__img-wrapper {
        display: grid;
        grid-template-rows: 1fr;
        @include smooth-transition();
        overflow: hidden;
        &.is-hidden {
            grid-template-rows: 0fr;

            .profil__img-content {
                @include smooth-transition();
                transform: translateY(50px);
            }
        }
    }
    &__img-content {
        min-height: 0;
        @media screen and (max-width: $mobile) {
            display: flex;
            justify-content: center;
        }
    }
    &__img {
        aspect-ratio: 1;
        border-radius: $main-border-radius;
        overflow: hidden;
        width: 100%;
        margin-bottom: 20px;
        @media screen and (max-width: $mobile) {
            width: 90px;
            height: 90px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        font-size: var(--title-font-size);
        white-space: nowrap;
    }
}