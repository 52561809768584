@import "/src/styles/variables";

.about-description {
    --description-spacing: 30px;
    p {
        padding: var(--block-padding);
        background-color: $light-dark;
        border-radius: $main-border-radius;
        @media screen and (max-width: $mobile) {
            padding: 1rem;
        }
        &:not(:last-child) {
            margin-bottom: var(--description-spacing);
        }
    }
    @media screen and (max-width: $mobile) {
        text-align: center;
        --description-spacing: 20px;
    }
}