@import "/src/styles/variables";

.swiper.slider-filters {
    position: relative;

    @media screen and (max-width: $mobile) {
        left: calc(var(--app-padding)* -1);
        right: calc(var(--app-padding)* -1);
        padding: 0 var(--app-padding);
        width: calc(100% + var(--app-padding)* 2);
    }
    .slider-filters__filter {
        pointer-events: none;
        input {
            display: none;
        }
        label {
            pointer-events: all;
        }
        &:not(:last-child) {
            margin-right: $gap-wrapper-cta;
        }
    }
}