@import "/src/styles/variables";

.about {
    --gap: 100px;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gap);
    width: 100%;
    height: 100%;
    margin: auto;
    position: relative;
    z-index: 1;
    @media screen and (max-width: $mobile) {
        --gap: 30px;
        flex-direction: column;
        // padding-bottom: 75px;
    }
    &__avatar {
        width: calc(50% - (var(--gap)/2));
        @media screen and (max-width: $mobile) {
            width: 80%;
            max-width: 400px;
            padding: 40px;
        }
    }
    &__description {
        flex: 1 1 calc(50% - (var(--gap)/2));
    }
}