@import "/src/styles/variables";

.main {
    height: 100%;
    width: 100%;
    flex: 1;
    overflow: hidden;
    @media screen and (max-width: $mobile) {
        overflow: unset;
    }
}