form {
    display: flex;
    flex-direction: column;
    gap: 30px;

    fieldset {
        display: flex;
        flex-direction: column;
        gap: 20px;

        legend {
            margin-bottom: 30px;
            font-weight: bold;
            @extend .text-medium;
        }
    }

    .error-message {
        font-size: 14px;
        color: rgb(216, 0, 0);
    }

    .field-group {
        display: flex;
        align-items: flex-end;
        gap: 20px;
    }
}