//COLORS
$primary: #4D2EAA;
$secondary: #EB6633;
$tertiary: #4A775D;
$quaternary: #F2B641;

$primary-light: lighten(#4D2EAA, 30%);
$secondary-light: lighten(#EB6633, 50%);
$tertiary-light: lighten(#4A775D, 30%);
$quaternary-light: lighten(#F2B641, 50%);

$light: #EBE7F6;
$dark: #1E1E1E;
$light-dark: lighten($dark, 5%);
$lighten-dark: lighten($dark, 15%);

//TRANSITION
$transition-duration: .5s;

//FONTS
$decorated-font: 'Bogam', 'Anton' , serif;
// $decorated-font: monospace;
$main-font: 'Inter', sans-serif;

//OTHERS
$gap-wrapper-cta: 10px;
$main-border-radius: 20px;

//MEDIA QUERIES
$mobile: 768px;
$tablet: 1024px;