@import "/src/styles/variables";

.nav {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    &__item {
        cursor: pointer;
        &.active {
            font-weight: 800;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: -20px;
                top: 50%;
                transform: translateY(-50%);
                width: 10px;
                height: 10px;
                border-radius: 10px;
                background-color: $primary;
            }
        }
        &::after {
            display: block;
            content: attr(label);
            font-weight: 800;
            height: 1px;
            color: transparent;
            overflow: hidden;
            visibility: hidden;
        }
    }
}