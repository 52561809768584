@import "./variables";

@mixin font-main {
    font-family: $main-font;
    line-height: 1.6;
}

@mixin font-decorated {
    font-family: $main-font;
    font-weight: bold;
    line-height: 1.2;
}

@mixin smooth-transition($property: all, $duration: $transition-duration, $delay: 0s, $ease: cubic-bezier(0.25, 0.8, 0.25, 1)) {
    transition: $property $duration $delay $ease;
    -webkit-transition: $property $duration $delay $ease; // Support pour les anciens navigateurs
    -moz-transition: $property $duration $delay $ease;
    -o-transition: $property $duration $delay $ease;
}