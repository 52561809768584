@import "/src/styles/variables";

.popin {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 1;
    outline: none;
    border: none;
    background: transparent;
    color: $dark;
    &__inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &__content {
            overflow: auto;
            width: 100%;
            max-width: 500px;
            max-height: 500px;
            padding: var(--block-padding);
            border-radius: $main-border-radius;
            background-color: $light;
            display: flex;
            flex-direction: column;
            gap: 40px;
            &__btn-close {
                margin-left: auto;
            }
        }
        &:before {
            content: '';
            background-color: rgba($color: $light-dark, $alpha: .5);
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
        }
    }
}