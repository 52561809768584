@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

@font-face {
    font-family: 'Bogam';
    src: url('../assets/fonts/Bogam.woff') format('woff'),
         url('../assets/fonts/Bogam.otf') format('opentype'),
         url('../assets/fonts/Bogam.ttf') format('truetype');
}


:root {
    --vh: 1vh;
    --global-font-size: 16px;
    --title-font-size: 32px;
    --container-big-padding: 60px;
    --container-small-padding: 0px;
    --global-gap: 30px;
    --app-padding: 40px;
    --block-padding: 30px;
    @media screen and (max-width: $mobile) {
        --global-font-size: 14px;
        --title-font-size: 28px;
        --container-big-padding: var(--container-small-padding);
        --app-padding: 1.5rem;
    }
}

body {
    @include font-main;
    // cursor: none;
}

* {
    box-sizing: border-box;
}

.app {
    height: 100vh;
    display: flex;
    background-color: $dark;
    font-size: var(--global-font-size);
    color: $light;
    font-weight: 400;
    min-width: 350px;
    min-height: 750px;
    gap: var(--global-gap);
    padding: var(--app-padding);
    @media screen and (max-width: $mobile) {
        gap: 30px;
        height: auto;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        flex-direction: column;
    }
}

img {
    width: 100%;
    height: auto;
}

h1, h2, h3, h4 {
    @include font-decorated;
}

button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-family: inherit;
    font-size: var(--global-font-size);
    color: inherit;
}

a {
    color: inherit;
}

table td {
    padding: 20px;
}

.text-small {
    font-size: 14px;
}
.text-medium {
    font-size: 20px;
}
.text-big {
    font-size: var(--title-font-size);
}
.text-body {
    font-size: var(--global-font-size);
}

.text-primary {
    color: $primary;
}
.text-black {
    color: $dark;
}
.text-white {
    color: $light;
}

.flex {
    display: flex;
    gap: 10px;
}
.flex-col {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}
.align-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}