@import "/src/styles/variables";

.socials {
    display: flex;
    justify-content: center;
    gap: $gap-wrapper-cta;
    flex-wrap: wrap;
    &__item {
        // flex: 1;
        &--cv {
            width: 100%;
        }
    }
}