@import "/src/styles/variables";

.project-panel {
    padding: var(--block-padding);
    background-color: $light-dark;
    border-radius: $main-border-radius;

    &__heading {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        gap: 30px;
    }

    .project-table {
        width: 100%;
        thead td {
            background-color: $primary;
            font-weight: bold;
            &:first-child {
                border-radius: $main-border-radius 0 0 $main-border-radius;
            }
            &:last-child {
                border-radius: 0 $main-border-radius $main-border-radius 0;
            }
        }
        tbody td {
            border-bottom: 1px solid $lighten-dark;
            vertical-align: bottom;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}