@import "/src/styles/variables";

.portfolio {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--global-gap);
    &__navigation {
        display: flex;
        gap: 50px;
        align-items: center;
        justify-content: space-between;
    }
    &__slider {
        height: 100%;
        user-select: none;
        width: 100%;
        border-radius: $main-border-radius;
        overflow: hidden;
        &__item {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            animation: slideLeft 0.5s both;
            &:not(:last-child) {
                padding-right: var(--global-gap);
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        &__item {
            animation: slideTop 0.5s both;
        }
    }

    &__filters {
        overflow: hidden;
    }

    .swiper-slide {
        width: unset;
    }
}


@keyframes slideLeft {
    0% {
        transform: translateX(100px); 
        opacity: 0;
    }
    100% { 
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideTop {
    0% {
        transform: translateY(100px); 
        opacity: 0;
    }
    100% { 
        transform: translateY(0);
        opacity: 1;
    }
}