@import "/src/styles/variables";
@import "/src/styles/mixins";

.about-avatar {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    &__illustration {
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50% 50% $main-border-radius $main-border-radius;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            background-color: $primary;
            z-index: -1;
        }

        &::before {
            top: -7%;
            left: -7%;
            width: 60%;
            height: 60%;
            border-radius: 400% 100% 100% 100%;
            animation: subtleFloatBefore 12s ease-in-out infinite;
        }

        &::after {
            bottom: -7%;
            right: -7%;
            width: 30%;
            height: 30%;
            border-radius: 400% 100% 100% 100%;
            animation: subtleFloatAfter 15s ease-in-out infinite;
        }
    }
}

// Animation avec plus d'étapes pour ::before
@keyframes subtleFloatBefore {
    0% {
        transform: translate3d(0%, 0%, 0) rotate(0deg);
    }
    20% {
        transform: translate3d(3%, -2%, 0) rotate(1deg);
    }
    40% {
        transform: translate3d(-4%, 3%, 0) rotate(-2deg);
    }
    60% {
        transform: translate3d(5%, -4%, 0) rotate(2deg);
    }
    80% {
        transform: translate3d(-3%, 2%, 0) rotate(-1deg);
    }
    100% {
        transform: translate3d(0%, 0%, 0) rotate(0deg);
    }
}

// Animation avec plus d'étapes pour ::after
@keyframes subtleFloatAfter {
    0% {
        transform: translate3d(0%, 0%, 0) rotate(0deg);
    }
    20% {
        transform: translate3d(-2%, 3%, 0) rotate(-1deg);
    }
    40% {
        transform: translate3d(4%, -3%, 0) rotate(2deg);
    }
    60% {
        transform: translate3d(-5%, 4%, 0) rotate(-2deg);
    }
    80% {
        transform: translate3d(3%, -2%, 0) rotate(1deg);
    }
    100% {
        transform: translate3d(0%, 0%, 0) rotate(0deg);
    }
}
