@import "/src/styles/variables";

.error {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 100px;
    &__message {
        &__link {
            text-decoration: underline;
        }
    }
    &__face {
        &__eye {
            position: relative;
            display: inline-block;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            border: 2px solid $primary;
            &:after { /*pupil*/
                position: absolute;
                bottom: 10px;
                right: 17px;
                width: 10px;
                height: 10px;
                background: $primary;
                border-radius: 50%;
                content: " ";
            }
        }
    }
}

