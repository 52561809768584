@import "/src/styles/variables";

.parameters {
    position: fixed;
    bottom: var(--app-padding);
    right: var(--app-padding);
    padding: 10px;
    border-radius: $main-border-radius;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &__icon {
        fill: #fff;
    }
}