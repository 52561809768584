@import "/src/styles/variables";

.footer {
    flex: none;
    margin-top: auto;
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 30px;
        &__job {
            margin-right: auto;
        }
        &__credits {
            margin-left: auto;
        }
    }
}